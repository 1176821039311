@tailwind base;
@tailwind components;
@tailwind utilities;

.curve-underline {
  position: relative;
  text-decoration: none;
}

.curve-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  border-top: solid 3px #05094f;
  left: 0;
  bottom: -10px;
  border-radius: 50%;
  height: 8px;
}

.sticky-nav {
  animation: sticky-nav 300ms;
}

@keyframes sticky-nav {
  0% {
    transform: translateY(-6em);
  }

  100% {
    transform: translateY(0);
  }
}

/* -------------------------------- */

.overflow-x-scroll {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin; /* for Firefox */
  scrollbar-color: #ccc #f0f0f0; /* for Firefox */
}

/* Optional: style the scrollbar for Webkit browsers (Chrome, Safari) */
.overflow-x-scroll::-webkit-scrollbar {
  width: 12px;
}

.overflow-x-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.overflow-x-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* body {
  height: 100svh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.react-calendar__tile--active {
  background: #05094f !important;
  color: white;
}

.dropdown:hover .dropdown-menu {
  display: block !important;
}

.bubble {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: inset 0 0 25px rgba (255, 255, 255, 0.25);
  animation: animate_4010 8s ease-in-out infinite;
}

.bubble:nth-child(2) {
  position: relative;
  zoom: 0.45;
  left: -10px;
  top: -100px;
  animation-delay: -4s;
}

.bubble:nth-child(3) {
  position: relative;
  zoom: 0.45;
  right: -80px;
  top: -300px;
  animation-delay: -6s;
}

.bubble:nth-child(4) {
  position: relative;
  zoom: 0.35;
  left: -120px;
  bottom: -200px;
  animation-delay: -3s;
}

.bubble:nth-child(5) {
  position: relative;
  zoom: 0.5;
  left: 0px;
  top: 200px;
  animation-delay: -5s;
}

@keyframes animate_4010 {
  0%,
  100% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }
}

.bubble::before {
  content: "";
  position: absolute;
  top: 50px;
  left: 45px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: lightpink;
  z-index: 10;
  filter: blur(2px);
}

.bubble::after {
  content: "";
  position: absolute;
  top: 80px;
  left: 80px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: blue;
  z-index: 10;
  filter: blur(2px);
}

.bubble span {
  position: absolute;
  border-radius: 50%;
}

.bubble span:nth-child(1) {
  inset: 10px;
  border-left: 15px solid #0fb4ff;
  filter: blur(8px);
}

.bubble span:nth-child(2) {
  inset: 10px;
  border-right: 15px solid #ff4484;
  filter: blur(8px);
}

.bubble span:nth-child(3) {
  inset: 10px;
  border-top: 15px solid #ffeb3b;
  filter: blur(8px);
}

.bubble span:nth-child(4) {
  inset: 30px;
  border-left: 15px solid #ff4484;
  filter: blur(12px);
}

.bubble span:nth-child(5) {
  inset: 10px;
  border-bottom: 10px solid #fff;
  filter: blur(8px);
  transform: rotate(330deg);
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev::after {
  content: "next";
  color: gray;
  display: none;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: gray;
  line-height: 100;
  display: none;
}

.swiper-pagination-bullet-active {
  background-color: gray;
  display: none;
}



