@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .layout {
    @apply max-w-8xl mx-auto overflow-hidden  w-full md:px-10;
  }
}
* {
  /* user-select: none; */
}
::-webkit-scrollbar {
  width: 30px;
}
@import url("https://fonts.googleapis.com/css2?family=Boldonse&display=swap");
.Boldonse {
  font-family: "Boldonse", system-ui;
  font-weight: 400;
  font-style: normal;
}

.zoom-in-out-element {
  animation: zoom-in-zoom-out 10s ease infinite alternate;
}

@keyframes zoom-in-zoom-out {
  0% {
    font-size: 36px;
    transform: scale(1, 1);
  }
  50% {
    font-size: 30px;
    transform: scale(1.5, 1.5);
  }
  100% {
    font-size: 36px;
    transform: scale(1, 1);
  }
}

.box-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.7), 0 0 20px rgba(30, 144, 255, 0.6),
    0 0 30px rgba(135, 206, 250, 0.5), 0 0 40px rgba(176, 224, 230, 0.4),
    0 0 50px rgba(240, 248, 255, 0.3), 0 0 60px rgba(255, 255, 255, 0.1); /* Full White */
}

.pop-hover {
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.pop-hover:hover {
  transform: scale(1.1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.grid-cols-auto-fit-100 {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
.grid-cols-auto-fit-200 {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.grid-cols-auto-fit-300 {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.grid-cols-auto-fit-400 {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}
.grid-cols-auto-fit-500 {
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
}
